exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-note-microcms-note-note-id-jsx": () => import("./../../../src/pages/note/{microcmsNote.noteId}.jsx" /* webpackChunkName: "component---src-pages-note-microcms-note-note-id-jsx" */),
  "component---src-pages-notes-jsx": () => import("./../../../src/pages/notes.jsx" /* webpackChunkName: "component---src-pages-notes-jsx" */),
  "component---src-pages-test-2-jsx": () => import("./../../../src/pages/test2.jsx" /* webpackChunkName: "component---src-pages-test-2-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-template-note-page-jsx": () => import("./../../../src/template/note-page.jsx" /* webpackChunkName: "component---src-template-note-page-jsx" */)
}

